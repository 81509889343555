import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export function textImageCutout() {
	// First, set a variable for the block type
	const containers = document.querySelectorAll('.text-image-cutout')

	// Then check if the block is on the page
	if (containers) {
		// If it is, loop through all blocks and apply a timeline to each
		for (let i = 0; i < containers.length; i++) {
			const container = containers[i]
			const animUp = container.querySelector('.anim-up')

			// Start with the block transparent to avoid flash of content pre-anim
			gsap.set(container, { autoAlpha: 1 })

			// Text effect timeline
			let tl = new gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'top 70%', // start when the top of the block reaches 70% from the top of the viewport
					toggleActions: 'play none resume reverse', // onEnter, onLeave, onEnterBack, onLeaveBack
					markers: false,
				},
			})

			tl.from(animUp, {
				duration: 1,
				autoAlpha: 0,
				y: 100,
			})
		}
	}
}
