// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle'

// import styles bundle
import 'swiper/css/bundle'

// init Swiper:
export function carousels() {
	//-------------------------------------------------------------
	// Get all swiper-text-images instances on the page
	const swiperNews = document.querySelectorAll('.swiper-news')

	// Loop through them
	for (const swiperContainer of swiperNews) {
		var mySwiper = new Swiper(swiperContainer, {
			loop: false,
			slidesPerView: 1.2,
			spaceBetween: 20,

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			breakpoints: {
				640: {
					slidesPerView: 2.2,
				},

				1024: {
					slidesPerView: 3.2,
				},
			},
		})
	}

	//-------------------------------------------------------------
	// Get all swiper-text-images instances on the page
	const swiperTextImagesInstances = document.querySelectorAll(
		'.swiper-text-images'
	)

	// Loop through them
	for (const swiperContainer of swiperTextImagesInstances) {
		var mySwiper = new Swiper(swiperContainer, {
			loop: false,
			slidesPerView: 1,
			spaceBetween: 10,

			// If we need pagination
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		})
	}

	//-------------------------------------------------------------
	// Get all swiper-standard instances on the page
	const swiperInstances = document.querySelectorAll('.swiper-standard')

	// Loop through them
	for (const swiperContainer of swiperInstances) {
		var mySwiper = new Swiper(swiperContainer, {
			// Optional parameters
			loop: true,

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			// If we need pagination
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
		})
	}

	//-------------------------------------------------------------
	// Get all swiper-images instances on the page
	const swiperImagesInstances = document.querySelectorAll('.swiper-images')

	// Loop through them
	for (const swiperContainer of swiperImagesInstances) {
		var mySwiper = new Swiper(swiperContainer, {
			// Optional parameters
			loop: true,
			autoplay: {
				delay: 4500,
			},
			effect: 'fade',
			speed: 3000,
			fadeEffect: {
				crossFade: true,
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		})

		// Playback buttons - pause/resume
		const playbackBtns = document.querySelectorAll('.swiper-button-playback')

		for (let i = 0; i < playbackBtns.length; i++) {
			let btn = playbackBtns[i]
			let paused = false
			let pauseIcon = btn.querySelector('.swiper-icon-pause')
			let playIcon = btn.querySelector('.swiper-icon-play')

			btn.addEventListener('click', () => {
				if (paused == false) {
					mySwiper.autoplay.stop()
					pauseIcon.classList.add('hidden')
					playIcon.classList.remove('hidden')
					paused = true
				} else {
					mySwiper.autoplay.start()
					pauseIcon.classList.remove('hidden')
					playIcon.classList.add('hidden')
					paused = false
				}
			})
		}
	}

	//-------------------------------------------------------------
	// Get all swiper-project-header instances on the page
	const projectHeaderSwiper = document.querySelectorAll(
		'.swiper-project-header'
	)

	// Loop through them
	for (const swiperContainer of projectHeaderSwiper) {
		var mySwiper = new Swiper(swiperContainer, {
			// Optional parameters
			loop: true,
			autoplay: {
				delay: 4500,
			},
			effect: 'fade',
			speed: 3000,
			fadeEffect: {
				crossFade: true,
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		})

		// Pause buttons
		const pauseBtns = document.querySelectorAll('.swiper-button-playback')

		for (let i = 0; i < pauseBtns.length; i++) {
			let btn = pauseBtns[i]
			let paused = false
			let pauseIcon = btn.querySelector('.swiper-icon-pause')
			let playIcon = btn.querySelector('.swiper-icon-play')

			btn.addEventListener('click', () => {
				if (paused == false) {
					mySwiper.autoplay.stop()
					pauseIcon.classList.add('hidden')
					playIcon.classList.remove('hidden')
					paused = true
				} else {
					mySwiper.autoplay.start()
					pauseIcon.classList.remove('hidden')
					playIcon.classList.add('hidden')
					paused = false
				}
			})
		}
	}
}
