import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export function heroTextBackground() {
	// First, set a variable for this page header
	const container = document.querySelector('#hero-text-background')

	if (container) {
		// If it exists, set variables for elements inside it, inside the scope of the container
		const heroAnim05 = container.querySelector('.hero-anim-05')
		const heroAnim10 = container.querySelector('.hero-anim-10')
		const heroAnim15 = container.querySelector('.hero-anim-15')
		const heroTextBackgroundImage = container.querySelector(
			'#hero-text-background-image'
		)

		let tl = new gsap.timeline({
			scrollTrigger: {
				trigger: container,
				start: 'top 80px', // Start when the top of the block is 80px from the top of the viewport (height of the nav)
				end: 'bottom top', // End animation when the bottom of heroTextBackgroundImage touches the top of the viewport
				scrub: true, // this ties the animation to scroll position instead of duration
			},
		})

		tl.to(heroTextBackgroundImage, {
			// Move from 0 to +200px
			y: 200,
		})

		if (heroAnim05) {
			gsap.from(heroAnim05, {
				duration: 1,
				autoAlpha: 0,
				y: 100,
				delay: 0.5,
			})
		}

		if (heroAnim10) {
			gsap.from(heroAnim10, {
				duration: 1,
				autoAlpha: 0,
				y: 100,
				delay: 1,
			})
		}

		if (heroAnim15) {
			gsap.from(heroAnim15, {
				duration: 1,
				autoAlpha: 0,
				y: 100,
				delay: 1.5,
			})
		}
	}
}
