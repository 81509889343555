// Import CSS for Vite. Path is relative to this JS file
import '../pcss/app.pcss'

// Set animation options
let options = {
	container: document.getElementById('animated-logo'),
	renderer: 'svg',
	loop: false,
	autoplay: false, // don't play on page load
	path: '/dist/logo-v7.json',
}

let direction = 1
let headerAnim = lottie.loadAnimation(options)
let logo = document.getElementById('animated-logo')

// Play forwards on hover
logo.addEventListener('mouseenter', (e) => {
	headerAnim.setDirection(direction)
	headerAnim.play()
})

// Play backwards on mouse leave
logo.addEventListener('mouseleave', (e) => {
	headerAnim.setDirection(-direction)
	headerAnim.play()
})

// Other stuff
import { lightBox } from './modules/lightBox'
import { carousels } from './modules/swiper'

import { heroBuildingCutoutSkyText } from './animations/heroBuildingCutoutSkyText'
import { heroTextBackground } from './animations/heroTextBackground'
import { heroTextBackgroundVideo } from './animations/heroTextBackgroundVideo'
import { heroTextImage } from './animations/heroTextImage'

import { textImageContained } from './animations/textImageContained'
import { textImageCutout } from './animations/textImageCutout'
import { textImageBackground } from './animations/textImageBackground'
import { textSlider } from './animations/textSlider'
import { textIcons } from './animations/textIcons'
import { projectsSelect } from './animations/projectsSelect'
import { cutoutTextAbove } from './animations/cutoutTextAbove.js'
import { twoColImageText } from './animations/twoColImageText.js'
import { textBoxes } from './animations/textBoxes.js'
import { callToAction } from './animations/callToAction.js'
import { text } from './animations/text.js'

// Cookies for the popup
import Cookies from 'js-cookie'
window.Cookies = Cookies
import { gsap } from 'gsap'

Alpine.data('popUpCookie', () => ({
	visible: false,
	delay: true,
	init() {
		setTimeout(() => {
			this.delay = false
			this.visible = Cookies.get('popupClosed') ? false : true

			if (this.visible) {
				gsap.from('.popup-wrap', { duration: 1, autoAlpha: 0 })
				gsap.from('.popup-content', { duration: 1, autoAlpha: 0, delay: 1 })
				gsap.to('.popup-blur', {
					duration: 2,
					backdropFilter: 'blur(10px)',
					delay: 1,
				})
			}
		}, 3000) // Delay for 3 seconds
	},
	hide() {
		this.visible = false
		Cookies.set('popupClosed', 'true', { expires: 3 })

		gsap.to('.popup-wrap', { duration: 1, autoAlpha: 0 })
	},
}))

// Initialise alpine.js
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.start()

// Run imported functions
document.addEventListener('DOMContentLoaded', function () {
	lightBox()
	carousels()

	heroBuildingCutoutSkyText()
	heroTextBackground()
	heroTextBackgroundVideo()
	heroTextImage()

	textImageContained()
	textImageCutout()
	textImageBackground()
	textSlider()
	textIcons()
	projectsSelect()
	cutoutTextAbove()
	twoColImageText()
	textBoxes()
	callToAction()
	text()
})

// Use JS to remove and add the right button classes to Freeform submit button
// Yes, it's hacky - but the alternative is maintaining a Freeform template
document.addEventListener('DOMContentLoaded', function () {
	// Loop through Freeform elements and change the classes
	const formButtons = document.querySelectorAll('.freeform-form button')
	const formRows = document.querySelectorAll('.freeform-form .flex')
	const formFields = document.querySelectorAll('.freeform-form input')
	const formAreas = document.querySelectorAll('.freeform-form textarea')

	// https://css-tricks.com/a-bunch-of-options-for-looping-over-queryselectorall-nodelists/#aa-for-of
	for (const button of formButtons) {
		button.classList.remove(
			'bg-blue-500',
			'hover:bg-blue-700',
			'text-white',
			'font-bold',
			'py-2',
			'px-4',
			'rounded',
			'mr-2'
		)
		button.classList.add('btn-blue', 'btn')
	}

	// Loop through Freeform rows and change the margins
	for (const row of formRows) {
		row.classList.remove('mb-6')
		row.classList.add('mb-2')
	}

	// Loop through Freeform inputs and change the borders
	for (const input of formFields) {
		input.classList.remove('border-gray-200', 'rounded')
		input.classList.add('border-blue')
	}

	// Loop through Freeform inputs and change the borders
	for (const textarea of formAreas) {
		textarea.classList.remove('border-gray-200', 'rounded')
		textarea.classList.add('border-blue')
	}
})

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
	import.meta.hot.accept(() => {
		console.log('HMR')
	})
}
