import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export function textImageBackground() {
	// First, set a variable for the block type
	const containers = document.querySelectorAll('.text-image-background')

	// Then check if the block is on the page
	if (containers) {
		// If it is, loop through all blocks and apply a timeline to each
		for (let i = 0; i < containers.length; i++) {
			const container = containers[i]
			const animUp = container.querySelector('.anim-up')
			const image = container.querySelector('.anim-image')

			// Start with the block transparent to avoid flash of content pre-anim
			gsap.set(container, { autoAlpha: 1 })

			// Text effect timeline
			let tl = new gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'top 70%', // start when the top of the block reaches 70% from the top of the viewport
					toggleActions: 'play none resume reverse', // onEnter, onLeave, onEnterBack, onLeaveBack
					markers: false,
				},
			})

			// Image timeline
			let imageTimeline = new gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'top 70%', // when the top of the block hits 70% from the top of the viewport
					end: 'bottom top', // when the bottom of the block hits the top of the viewport
					scrub: true, // this ties the animation to scroll position instead of duration
					markers: false,
				},
			})

			tl.from(animUp, {
				duration: 1,
				autoAlpha: 0,
				y: 100,
			})

			imageTimeline.from(image, {
				// Start from y+200 and move to 0 by the time the bottom of the block reaches the top of the viewport
				y: 200,
			})
		}
	}
}
