import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export function textBoxes() {
	// First, set a variable for the block type
	const containers = document.querySelectorAll('.text-boxes')

	// Then check if the block is on the page
	if (containers) {
		// If it is, loop through all blocks and apply a timeline to each
		for (let i = 0; i < containers.length; i++) {
			const container = containers[i]
			const animIntro = container.querySelector('.anim-intro')
			const animBox1 = container.querySelector('.anim-1')
			const animBox2 = container.querySelector('.anim-2')
			const animBox3 = container.querySelector('.anim-3')
			const animBox4 = container.querySelector('.anim-4')

			// Start with the block transparent to avoid flash of content pre-anim
			gsap.set(container, { autoAlpha: 1 })

			// Text effect timeline
			let tl = new gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'top 70%', // start when the top of the block reaches 70% from the top of the viewport
					toggleActions: 'play none resume reverse', // onEnter, onLeave, onEnterBack, onLeaveBack
					markers: false,
				},
			})

			if (animIntro) {
				tl.from(animIntro, {
					autoAlpha: 0,
					y: 100,
				})
			}

			if (animBox1) {
				tl.from(animBox1, {
					duration: 0.5,
					autoAlpha: 0,
					y: 100,
				})
			}
			if (animBox2) {
				tl.from(animBox2, {
					duration: 0.5,
					autoAlpha: 0,
					y: 100,
				})
			}
			if (animBox3) {
				tl.from(animBox3, {
					duration: 0.5,
					autoAlpha: 0,
					y: 100,
				})
			}
			if (animBox4) {
				tl.from(animBox4, {
					duration: 0.5,
					autoAlpha: 0,
					y: 100,
				})
			}
		}
	}
}
