import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export function cutoutTextAbove() {
	// First, set a variable for the block type
	const containers = document.querySelectorAll('.cutout-text-above')

	// Then check if the block is on the page
	if (containers) {
		// If it is, loop through all blocks and apply a timeline to each
		for (let i = 0; i < containers.length; i++) {
			const container = containers[i]
			const col1 = container.querySelectorAll('.anim-col-1')
			const col2 = container.querySelectorAll('.anim-col-2')
			const image = container.querySelectorAll('.anim-image')

			// Start with the block transparent to avoid flash of content pre-anim
			gsap.set(container, { autoAlpha: 1 })

			// Text effect timeline
			let textTimeline = new gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'top 70%', // start when the top of the block reaches 70% from the top of the viewport
					toggleActions: 'play none resume reverse', // onEnter, onLeave, onEnterBack, onLeaveBack
					markers: false,
				},
			})

			// Image timeline
			let imageTimeline = new gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'top 70%', // start when the top of the block reaches 70% from the top of the viewport
					end: 'bottom top', // end when the bottom of this block reaches the top of the viewport
					scrub: true,
					markers: false,
				},
			})

			textTimeline.add('start')
			textTimeline.from(
				col1,
				{
					delay: 0.4,
					autoAlpha: 0,
					y: 100,
				},
				'start'
			)
			textTimeline.from(
				col2,
				{
					delay: 0.8,
					autoAlpha: 0,
					y: 100,
				},
				'start'
			)
			imageTimeline.from(image, {
				// Start from y+300 and move to 0 by the time the bottom of the block reaches the top of the viewport
				y: 300,
			})
		}
	}
}
