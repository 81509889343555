import { gsap } from 'gsap'

export function heroTextImage() {
	// First, set a variable for this page header
	const container = document.querySelector('#hero-text-image')

	if (container) {
		// If it exists, set variables for elements inside it, inside the scope of the container
		const heroAnim05 = container.querySelector('.hero-anim-05')
		const heroAnim10 = container.querySelector('.hero-anim-10')
		const heroAnim15 = container.querySelector('.hero-anim-15')
		const heroAnim20 = container.querySelector('.hero-anim-20')
		const textImageBg = container.querySelector('#hero-text-image-bg')

		if (heroAnim05) {
			gsap.from(heroAnim05, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 0.5,
			})
		}

		if (heroAnim10) {
			gsap.from(heroAnim10, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 1,
			})
		}

		if (heroAnim15) {
			gsap.from(heroAnim15, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 1.5,
			})
		}

		if (heroAnim20) {
			gsap.from(heroAnim20, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 2,
			})
		}

		if (textImageBg) {
			gsap.from(textImageBg, {
				duration: 3,
				autoAlpha: 0,
				x: 500,
				delay: 0,
			})
		}
	}
}
