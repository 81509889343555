import lightGallery from 'lightgallery'

export function lightBox() {
	const hasElement = document.getElementsByClassName('gallery-photos')

	if (hasElement.length > 0) {
		lightGallery(document.getElementById('lightgallery'), {
			licenseKey: '7D654415-AE02447B-A3EA6583-EE6B880D',
			selector: 'a',
			allowMediaOverlap: true,
			download: false,

			mobileSettings: {
				controls: true,
				showCloseIcon: true,
				download: false,
			},
		})
	}
}
