import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export function projectsSelect() {
	// First, set a variable for the block type
	const containers = document.querySelectorAll('.projects-select')

	// Then check if the block is on the page
	if (containers) {
		// If it is, loop through all blocks and apply a timeline to each
		for (let i = 0; i < containers.length; i++) {
			const container = containers[i]
			const project1 = container.querySelectorAll('.anim-delay-1')
			const project2 = container.querySelectorAll('.anim-delay-2')
			const project3 = container.querySelectorAll('.anim-delay-3')
			const project4 = container.querySelectorAll('.anim-delay-4')

			// Start with the block transparent to avoid flash of content pre-anim
			gsap.set(container, { autoAlpha: 1 })

			// Text effect timeline
			let tl = new gsap.timeline({
				scrollTrigger: {
					trigger: container,
					start: 'top 70%', // start when the top of the block reaches 70% from the top of the viewport
					toggleActions: 'play none resume reverse', // onEnter, onLeave, onEnterBack, onLeaveBack
					markers: false,
				},
			})

			tl.add('start')
			tl.from(
				project1,
				{
					duration: 0.5,
					delay: 0.3,
					autoAlpha: 0,
					y: 100,
				},
				'start'
			)
			tl.from(
				project2,
				{
					duration: 0.5,
					delay: 0.6,
					autoAlpha: 0,
					y: 100,
				},
				'start'
			)
			tl.from(
				project3,
				{
					duration: 0.5,
					delay: 0.9,
					autoAlpha: 0,
					y: 100,
				},
				'start'
			)
			tl.from(
				project4,
				{
					duration: 0.5,
					delay: 1.2,
					autoAlpha: 0,
					y: 100,
				},
				'start'
			)
		}
	}
}
