import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export function twoColImageText() {
	// First, set a variable for the block type
	const blocks = document.querySelectorAll('.two-col-image-text')

	// Then check if the block is on the page
	if (blocks) {
		// Start with the block transparent to avoid flash of content pre-anim
		duration: 0.5, gsap.set(blocks, { autoAlpha: 1 })

		// If it is, loop through all blocks on the page

		for (let i = 0; i < blocks.length; i++) {
			const col = blocks[i].querySelectorAll('.anim-item')

			for (let i = 0; i < col.length; i++) {
				const animImage = col[i].querySelector('.anim-image')
				const animHeading = col[i].querySelector('.anim-heading')
				const animText = col[i].querySelector('.anim-text')
				const animButton = col[i].querySelector('.anim-button')

				// Text effect timeline
				let tl = new gsap.timeline({
					scrollTrigger: {
						trigger: col[i],
						start: 'top 70%', // start when the top of the block reaches 70% from the top of the viewport
						toggleActions: 'play none resume reverse', // onEnter, onLeave, onEnterBack, onLeaveBack
						markers: false,
					},
				})

				if (animImage) {
					tl.from(animImage, {
						duration: 0.5,
						autoAlpha: 0,
						y: 50,
					})
				}

				if (animHeading) {
					tl.from(animHeading, {
						duration: 0.5,
						autoAlpha: 0,
						y: 50,
					})
				}

				if (animText) {
					tl.from(animText, {
						duration: 0.5,
						autoAlpha: 0,
						y: 50,
					})
				}

				if (animButton) {
					tl.from(animButton, {
						duration: 0.5,
						autoAlpha: 0,
						y: 50,
					})
				}
			}
		}
	}
}
