import { gsap } from 'gsap'

export function heroBuildingCutoutSkyText() {
	// First, set a variable for this page header
	const container = document.querySelector('#hero-building-cutout-sky-text')

	if (container) {
		// If it exists, set variables for elements inside it, inside the scope of the container
		const heroAnim05 = container.querySelector('.hero-anim-05')
		const heroAnim10 = container.querySelector('.hero-anim-10')
		const heroAnim15 = container.querySelector('.hero-anim-15')
		const heroCutoutImage = container.querySelector('.hero-cutout-image')

		gsap.from(heroCutoutImage, {
			duration: 2,
			autoAlpha: 0,
			yPercent: 100,
		})

		if (heroAnim05) {
			gsap.from(heroAnim05, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 0.5,
			})
		}

		if (heroAnim10) {
			gsap.from(heroAnim10, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 1,
			})
		}

		if (heroAnim15) {
			gsap.from(heroAnim15, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 1.5,
			})
		}
	}
}
