import { gsap } from 'gsap'

export function heroTextBackgroundVideo() {
	// First, set a variable for this page header
	const container = document.querySelector('#hero-text-background-video')

	if (container) {
		// If it exists, set variables for elements inside it, inside the scope of the container
		const heroAnim05 = container.querySelectorAll('.hero-anim-05')
		const heroAnim10 = container.querySelectorAll('.hero-anim-10')
		const heroAnim15 = container.querySelectorAll('.hero-anim-15')
		const heroVideo = container.querySelector('#hero-anim-video')

		// Text effect timeline
		let tl = new gsap.timeline({
			scrollTrigger: {
				trigger: container,
				start: 'top 80px', // start when the top of the block is at -80px (mobile navbar) from the top of the viewport
				end: 'bottom top',
				scrub: true,
				markers: false,
			},
		})

		tl.to(heroVideo, {
			// Move from 0 to +200px
			y: 200,
		})

		if (heroAnim05) {
			gsap.from(heroAnim05, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 0.5,
			})
		}

		if (heroAnim10) {
			gsap.from(heroAnim10, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 1,
			})
		}

		if (heroAnim15) {
			gsap.from(heroAnim15, {
				duration: 0.5,
				autoAlpha: 0,
				y: 100,
				delay: 1.5,
			})
		}
	}
}
